import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'

import { APP_DEFAULT_LANGUAGE, ELanguage } from '~/core/features/ui/ui.model'

import { LocalStorageMock } from '../mocks'

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  static readonly PAGINATOR_PAGE_SIZE = 'PAGE_SIZE'
  static readonly STORAGE_IS_DARK = 'IS_DARK_THEME'
  static readonly STORAGE_LANG = 'LANGUAGE'
  static readonly DEFAULT_PAGE_SIZE = 10
  static readonly TOKEN = 'TOKEN'

  private localStorage: Storage

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.localStorage = isPlatformBrowser(this.platformId) ? localStorage : new LocalStorageMock()
  }

  changeThemeMode(isDarkTheme: boolean): void {
    this.set(LocalStorageService.STORAGE_IS_DARK, +isDarkTheme + '')
  }

  isDarkTheme(): boolean | null {
    return this.get(LocalStorageService.STORAGE_IS_DARK) !== null
      ? Boolean(+(this.get(LocalStorageService.STORAGE_IS_DARK) || 0))
      : null
  }

  changeLanguage(language: ELanguage): void {
    this.set(LocalStorageService.STORAGE_LANG, language)
  }

  getLanguage(): ELanguage {
    const language = this.get(LocalStorageService.STORAGE_LANG) as ELanguage
    return Object.values(ELanguage).includes(language) ? language : APP_DEFAULT_LANGUAGE
  }

  saveToken(token: string): void {
    token ? this.set(LocalStorageService.TOKEN, token) : this.clearToken()
  }

  getToken(): string {
    return this.get(LocalStorageService.TOKEN) || ''
  }

  clearToken(): void {
    localStorage.removeItem(LocalStorageService.TOKEN)
  }

  getPaginatorPageSize(): number {
    return Number(this.get(LocalStorageService.PAGINATOR_PAGE_SIZE)) || LocalStorageService.DEFAULT_PAGE_SIZE
  }

  savePaginatorPageSize(pageSize: number): void {
    this.set(LocalStorageService.PAGINATOR_PAGE_SIZE, String(pageSize))
  }

  private remove(key: string): void {
    this.localStorage.removeItem(key)
  }

  private get(key: string): string {
    return this.localStorage.getItem(key) || ''
  }

  private set(key: string, value: string): void {
    this.localStorage.setItem(key, value)
  }
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { map, Observable } from 'rxjs'

import {
  IBanUserDto,
  ICreateUserDto,
  IResponseListApiDto,
  IResponsePaginationApiDto,
  IRoleDto,
  ISearchPaginationData,
  IUpdateUserDto,
  IUserDto,
} from '~/core/models'
import { getUrlParams } from '~/utils/index'

const USER_PREFIX = 'user'
const ROLE_PREFIX = 'role'

@Injectable({
  providedIn: 'any',
})
export class AdminApiService {
  constructor(private http: HttpClient) {}

  // User

  getUserList(searchData: ISearchPaginationData): Observable<IResponsePaginationApiDto<IUserDto>> {
    const data = {
      search: '',
      sortBy: searchData.sortBy,
      order: searchData.order,
      page: searchData.paginatorData.page,
      perPage: searchData.paginatorData.perPage,
      filters: searchData.filters,
    }
    const urlParams = getUrlParams(data)

    return this.http.get<IResponsePaginationApiDto<IUserDto>>(`${USER_PREFIX}/list?${urlParams}`).pipe(
      map(res => ({
        ...res,
        data: res.data.map(u => this.parseUser(u)), // TODO: Remove after fix
      }))
    )
  }

  createUser(userData: ICreateUserDto): Observable<IUserDto> {
    return this.http.post<IUserDto>(`${USER_PREFIX}/create`, userData).pipe(map(u => this.parseUser(u))) // TODO: Remove after fix
  }

  updateUser(userData: IUpdateUserDto): Observable<IUserDto> {
    return this.http.patch<IUserDto>(`${USER_PREFIX}/update`, userData).pipe(map(u => this.parseUser(u))) // TODO: Remove after fix
  }

  banUser(userData: IBanUserDto): Observable<IUserDto> {
    return this.http.post<IUserDto>(`${USER_PREFIX}/ban`, userData).pipe(map(u => this.parseUser(u))) // TODO: Remove after fix
  }

  unbanUser(userId: number): Observable<IUserDto> {
    return this.http.post<IUserDto>(`${USER_PREFIX}/unban`, { userId }).pipe(map(u => this.parseUser(u))) // TODO: Remove after fix
  }

  deleteUser(userId: number): Observable<void> {
    return this.http.delete<void>(`${USER_PREFIX}/${userId}`)
  }

  // Role

  getRoleList(): Observable<IRoleDto[]> {
    return this.http.get<IResponseListApiDto<IRoleDto>>(`${ROLE_PREFIX}/list`).pipe(map(res => res.data))
  }

  addRole(name: string): Observable<IRoleDto> {
    const data = { name }
    return this.http.post<IRoleDto>(`${ROLE_PREFIX}/add`, data)
  }

  // TODO: Remove after fix
  private parseUser(user: IUserDto): IUserDto {
    return {
      ...user,
      apiKeys: user.api_keys,
    }
  }
}

export const getPageSizeOption = (initPageSizeOptions: number[], total: number, perPage: number): number[] => {
  const pageSizeOptions: number[] = []
  const maxSize = Math.max(total, perPage)

  for (const size of initPageSizeOptions) {
    pageSizeOptions.push(size)

    if (size >= maxSize) {
      break
    }
  }

  return pageSizeOptions
}

import { IPaginationDto } from '~/core/models'
import { LocalStorageService } from '~/core/services'

export const ADMIN_ROLE = 'admin'

export const FORECAST_LIST_PATH = 'forecasts'
export const NOT_FOUND_PATH = 'not-found'
export const SIGN_IN_PATH = 'sign-in'

export const APP = {
  NOTIFICATION_DURATION: 5,
  GUEST_DURATION: 10,
}

export const PAGINATION_DATA: IPaginationDto = {
  page: 1,
  perPage: LocalStorageService.DEFAULT_PAGE_SIZE,
  total: 0,
  pages: 1,
  hasNext: false,
  hasPrev: false,
  nextNum: null,
  prevNum: null,
}

export enum EAnnounceViewExpiredPeriod {
  Expired = -1,
  ExpiredSoon = 8,
}

export const FORECAST_SECTOR_MAP: { [key: string]: string } = {
  // 'Basic Materials': 'B. Materials',
  'Communication Services': 'Communications',
  'Consumer Cyclical': 'Cons. Cyclical',
  'Consumer Defensive': 'Cons. Defensive',
  'Financial Services': 'Financials',
}

export enum EForecastSector {
  'Basic Materials' = 'Basic Materials',
  'Communication Services' = 'Communication Services',
  'Consumer Cyclical' = 'Consumer Cyclical',
  'Consumer Defensive' = 'Consumer Defensive',
  'Financial Services' = 'Financial Services',
  'Healthcare' = 'Healthcare',
  'Industrials' = 'Industrials',
  'Technology' = 'Technology',
  'Utilities' = 'Utilities',
}

export enum EForecastCompanySize {
  '1:Micro' = '1:Micro',
  '2:Small' = '2:Small',
  '3:Mid' = '3:Mid',
  '4:Large' = '4:Large',
  '5:Mega' = '5:Mega',
  '6:Giga' = '6:Giga',
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { map, Observable } from 'rxjs'

import {
  IGuardPlanEntity,
  IGuardPlanEntityCreateDto,
  IGuardPlanSection,
  IGuardPlanSectionDto,
  IPlanDto,
  IResponseListApiDto,
  IResponsePaginationApiDto,
  IUserPermissionsDto,
} from '~/core/models'

const GUARD_PREFIX = 'guard'

@Injectable({
  providedIn: 'any',
})
export class GuardApiService {
  constructor(private http: HttpClient) {}

  getPlanList(): Observable<IResponsePaginationApiDto<IPlanDto>> {
    return this.http.get<IResponsePaginationApiDto<IPlanDto>>(`${GUARD_PREFIX}/plan/list`)
  }

  addPlan(name: string): Observable<IResponsePaginationApiDto<IPlanDto>> {
    return this.http.post<IResponsePaginationApiDto<IPlanDto>>(`${GUARD_PREFIX}/plan/add`, { name })
  }

  deletePlan(planId: number): Observable<IResponsePaginationApiDto<boolean>> {
    return this.http.delete<IResponsePaginationApiDto<boolean>>(`${GUARD_PREFIX}/plan/delete/${planId}`)
  }

  getGuardPlanSectionList(): Observable<IResponsePaginationApiDto<IGuardPlanSection>> {
    return this.http.get<IResponsePaginationApiDto<IGuardPlanSection>>(`${GUARD_PREFIX}/plan-section/list`)
  }

  getCurrentGuardPlanSection(): Observable<IGuardPlanSection> {
    return this.http.get<IGuardPlanSection>(`guard/section/current/list`)
  }

  getCurrentGuardPlanPermissions(): Observable<IUserPermissionsDto> {
    return this.http.get<IUserPermissionsDto>(`${GUARD_PREFIX}/plan/current`)
  }

  updateGuardPlanSections(settings: IGuardPlanSection[]): Observable<IResponsePaginationApiDto<IGuardPlanSection>> {
    return this.http.post<IResponsePaginationApiDto<IGuardPlanSection>>(`${GUARD_PREFIX}/plan-section/update`, {
      settings,
    })
  }

  addGuardSection(name: string): Observable<IResponsePaginationApiDto<IGuardPlanSection>> {
    return this.http.post<IResponsePaginationApiDto<IGuardPlanSection>>(`guard/add-guard-section`, { name })
  }

  getGuardSectionList(): Observable<IResponsePaginationApiDto<IGuardPlanSectionDto>> {
    return this.http.get<IResponsePaginationApiDto<IGuardPlanSectionDto>>(`guard/section/list`)
  }

  getGuardPlanEntityList(): Observable<IGuardPlanEntity[]> {
    return this.http.get<IResponseListApiDto<IGuardPlanEntity>>(`guard/plan-entity/list`).pipe(map(({ data }) => data))
  }

  getCurrentGuardPlanEntity(): Observable<IGuardPlanEntity> {
    return this.http.get<IGuardPlanEntity>(`guard/plan-entity/current/list`)
  }

  updateGuardPlanEntityList(settings: IGuardPlanEntityCreateDto[]): Observable<IGuardPlanEntity[]> {
    return this.http
      .post<IResponseListApiDto<IGuardPlanEntity>>(`guard/plan-entity/list`, { settings })
      .pipe(map(({ data }) => data))
  }
}

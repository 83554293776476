import { DatePipe } from '@angular/common'

import { ELanguage } from '~/core/features'
import { FORECAST_SECTOR_MAP } from '~const/index'

export const forecastNumber = (v: number | undefined, toFixed = -1, isCurrency = false): string => {
  if (typeof v !== 'number') {
    return ''
  }
  const isNegative = v < 0
  const value = Math.abs(v)
  let result =
    toFixed === -1
      ? new Intl.NumberFormat('en').format(value)
      : new Intl.NumberFormat('en', { minimumFractionDigits: toFixed, maximumFractionDigits: toFixed }).format(value)

  if (isCurrency) {
    result = `$${result}`
  }
  if (isNegative) {
    result = `-${result}`
  }

  return result
}

export const compactNumber = (num: number, lang: ELanguage, emptyString = '', isCurrency = true): string => {
  if (!num) {
    return emptyString
  }
  const compactNumber = Intl.NumberFormat(lang, { notation: 'compact' }).format(num)
  return `${isCurrency ? '$' : ''}${compactNumber}`
}

export const forecastPercentNumber = (v: number | undefined, toFixed = -1, isCurrency = false): string => {
  if (typeof v !== 'number') {
    return ''
  }
  return forecastNumber(v * 100, toFixed, isCurrency) + '%'
}

export const getForecastSectorName = (sector: string): string => {
  for (const key of Object.keys(FORECAST_SECTOR_MAP)) {
    if (key === sector) {
      return FORECAST_SECTOR_MAP[key]
    }
  }
  return sector
}

export const getForecastCompanySize = (companySize?: string): string => {
  return companySize?.includes(':') ? companySize.split(':')[1] : companySize || ''
}

export const getDaysBetween = (date1: Date, date2: Date): number => {
  const ONE_DAY = 1000 * 60 * 60 * 24
  const differenceMs = date1.getTime() - date2.getTime()
  return Math.floor(differenceMs / ONE_DAY)
}

export const getDateISOString = (date: Date): string => new DatePipe('en-US').transform(date, 'YYYY-MM-dd') || ''

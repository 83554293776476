import { drawRectangle, drawTextBg } from './d3.utils'

export function drawPdfLegend(
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  x: d3.ScaleLinear<number, number, never>,
  y: d3.ScaleLinear<number, number, never>,
  xPos: number,
  yPos: number,
  pdfConsensus: string,
  pdfForecastProximilar: string
): void {
  const padding = { x: 20, y: 10 }
  const gNode = chartGroup.append('g')

  const drawLegendInfo = (xOffset = 0, yOffset = 0): void => {
    gNode.selectAll('.legend-rect').remove()
    gNode.selectAll('text').remove()
    const xLegend = x(xPos) - xOffset
    const yLegend = y(yPos) + yOffset
    const lineYSize = 18
    const fSize = 13
    drawRectangle(gNode, xLegend, yLegend - 3 + lineYSize * 0, 5, 5, 'var(--proximilar-chart-info1-color)').attr(
      'class',
      'legend-rect'
    )
    drawRectangle(gNode, xLegend, yLegend + lineYSize * 1 - 3, 5, 5, 'var(--proximilar-chart-info2-color)').attr(
      'class',
      'legend-rect'
    )
    gNode
      .append('text')
      .attr('x', xLegend + 10)
      .attr('y', yLegend + lineYSize * 0)
      .text(pdfConsensus)
      .style('font-size', `${fSize}px`)
      .attr('alignment-baseline', 'middle')
      .attr('fill', 'var(--proximilar-font-color)')
    gNode
      .append('text')
      .attr('x', xLegend + 10)
      .attr('y', yLegend + lineYSize * 1)
      .text(pdfForecastProximilar)
      .style('font-size', `${fSize}px`)
      .attr('alignment-baseline', 'middle')
      .attr('fill', 'var(--proximilar-font-color)')
  }

  drawLegendInfo()
  const height = (gNode.node() as SVGTSpanElement).getBBox().height
  const width = (gNode.node() as SVGTSpanElement).getBBox().width + padding.x

  drawLegendInfo(width, height)
  drawTextBg(gNode, padding.x, padding.y, 1)
  drawLegendInfo(width, height)
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import {
  EPerformanceSummaryFlag,
  IPerformanceCntEst,
  IPerformanceMktCap,
  IPerformanceQuarter,
  IPerformanceQuarterTotal,
  IPerformanceSector,
} from '~/core/features/performance'
import { IResponseListApiDto, IResponseSortListApiDto } from '~/core/models'

@Injectable({
  providedIn: 'any',
})
export class PerformanceApiService {
  constructor(private http: HttpClient) {}

  getPerformanceQuarterList(summaryFlag: EPerformanceSummaryFlag): Observable<IPerformanceQuarter[]> {
    return this.http
      .get<IResponseListApiDto<IPerformanceQuarter>>(`performance-quarter-list?summaryFlag=${summaryFlag}`)
      .pipe(
        map(res =>
          res.data.map(quarter => ({
            ...quarter,
            epsWeBeatThem: quarter.epsWeBeatThem ? +quarter.epsWeBeatThem : 0,
            revWeBeatThem: quarter.revWeBeatThem ? +quarter.revWeBeatThem : 0,
            companyCount: quarter.companyCount ? +quarter.companyCount : 0,
          }))
        )
      )
  }

  getPerformanceQuarterTotalList(
    quarter: number,
    year: number,
    count: number,
    summaryFlag: EPerformanceSummaryFlag
  ): Observable<IPerformanceQuarterTotal[]> {
    return this.http
      .get<
        IResponseListApiDto<IPerformanceQuarterTotal>
      >(`performance-quarter-total-list/${year}/${quarter}?count=${count}&summaryFlag=${summaryFlag}`)
      .pipe(map(res => res.data.reverse()))
  }

  getPerformanceMktCap(
    year: number,
    quarter: number,
    sortBy: string,
    order: 'asc' | 'desc' | ''
  ): Observable<IResponseSortListApiDto<IPerformanceMktCap>> {
    // TODO: Find how to convert on the backend side
    return this.http
      .get<
        IResponseSortListApiDto<IPerformanceMktCap>
      >(`performance-mkt-cap/${year}/${quarter}?sortBy=${sortBy}&order=${order}`)
      .pipe(
        map(res => ({
          order: res.order,
          sortBy: res.sortBy,
          data: res.data.map(mktCap => ({
            ...mktCap,
            epsErrorReduction: +mktCap.epsErrorReduction,
            epsWeBeatThem: +mktCap.epsWeBeatThem,
            revErrorReduction: +mktCap.revErrorReduction,
            revWeBeatThem: +mktCap.revWeBeatThem,
          })),
        }))
      )
  }

  getPerformanceSector(
    year: number,
    quarter: number,
    sortBy: string,
    order: 'asc' | 'desc' | ''
  ): Observable<IResponseSortListApiDto<IPerformanceSector>> {
    // TODO: Find how to convert on the backend side
    // TODO: Add sectors to translate
    return this.http
      .get<
        IResponseSortListApiDto<IPerformanceSector>
      >(`performance-sector/${year}/${quarter}?sortBy=${sortBy}&order=${order}`)
      .pipe(
        map(res => {
          const order = res.order
          const sortBy = res.sortBy

          const communication = res.data.find(d => d.sector === 'Communication Services')
          const technology = res.data.find(d => d.sector === 'Technology')
          let data = res.data

          // TODO: Move to the backend

          if (communication && technology) {
            const count = communication.companyCount + technology.companyCount
            const epsWeBeatThem =
              (technology.epsWeBeatThem * technology.companyCount +
                communication.epsWeBeatThem * communication.companyCount) /
              count
            const revWeBeatThem =
              (technology.revWeBeatThem * technology.companyCount +
                communication.revWeBeatThem * communication.companyCount) /
              count
            const epsErrorReduction =
              (technology.epsErrorReduction * technology.companyCount +
                communication.epsErrorReduction * communication.companyCount) /
              count
            const revErrorReduction =
              (technology.revErrorReduction * technology.companyCount +
                communication.revErrorReduction * communication.companyCount) /
              count

            const newTechnology: IPerformanceSector = {
              ...technology,
              sector: 'Technology & Telecom',
              epsWeBeatThem,
              revWeBeatThem,
              epsErrorReduction,
              revErrorReduction,
            }

            data = data.reduce((values, value) => {
              if (value.sector === 'Technology') {
                values.push(newTechnology)
              }
              if (value.sector !== 'Communication Services' && value.sector !== 'Technology') {
                values.push(value)
              }
              return values
            }, [] as IPerformanceSector[])
          }

          return {
            order,
            sortBy,
            data: data.map(mktCap => ({
              ...mktCap,
              epsErrorReduction: +mktCap.epsErrorReduction,
              epsWeBeatThem: +mktCap.epsWeBeatThem,
              revErrorReduction: +mktCap.revErrorReduction,
              revWeBeatThem: +mktCap.revWeBeatThem,
            })),
          }
        })
      )
  }

  getPerformanceCntEst(
    year: number,
    quarter: number,
    sortBy: string,
    order: 'asc' | 'desc' | ''
  ): Observable<IResponseSortListApiDto<IPerformanceCntEst>> {
    // TODO: Find how to convert on the backend side
    return this.http
      .get<
        IResponseSortListApiDto<IPerformanceCntEst>
      >(`performance-cnt-est/${year}/${quarter}?sortBy=${sortBy}&order=${order}`)
      .pipe(
        map(res => ({
          order: res.order,
          sortBy: res.sortBy,
          data: res.data.map(mktCap => ({
            ...mktCap,
            epsErrorReduction: +mktCap.epsErrorReduction,
            epsWeBeatThem: +mktCap.epsWeBeatThem,
            revErrorReduction: +mktCap.revErrorReduction,
            revWeBeatThem: +mktCap.revWeBeatThem,
            companyCount: +mktCap.companyCount,
          })),
        }))
      )
  }
}

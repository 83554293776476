import * as d3 from 'd3'

import { IChartLineData } from '~/core/models'

import { drawRectangle, drawTextBg } from './d3.utils'
import { forecastNumber } from './forecast.utils'

const LINE_CENTER_SIZE = 7

export interface ITooltipSettings {
  lineX: d3.Selection<SVGLineElement, unknown, null, undefined>
  lineY: d3.Selection<SVGLineElement, unknown, null, undefined>
  lineXCenter: d3.Selection<SVGLineElement, unknown, null, undefined>
  lineYCenter: d3.Selection<SVGLineElement, unknown, null, undefined>
  lineXValue: d3.Selection<SVGGElement, unknown, null, undefined>
  lineYValue: d3.Selection<SVGGElement, unknown, null, undefined>
  tooltipTitle: d3.Selection<d3.BaseType, unknown, null, undefined>
  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined>
}

export function generateIsolineTooltip(el: any): {
  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined>
  tooltipTitle: d3.Selection<d3.BaseType, unknown, null, undefined>
} {
  const tooltip = d3.select(el).append('div').attr('class', 'chart-tooltip')
  tooltip.append('span').attr('class', 'title')

  const tooltipTitle = tooltip.selectChild('.title')
  tooltipTitle.style('color', 'var(--proximilar-chart-tooltip-color)')

  return { tooltip, tooltipTitle }
}

export function generateIsolineTooltipValues({
  el,
  chartGroup,
  x,
  y,
  xMin,
  xMax,
  yMin,
  yMax,
  height,
  width,
  tooltipTitleText,
  yToFixed,
  xToFixed,
}: {
  el: any
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>
  x: d3.ScaleLinear<number, number, never>
  y: d3.ScaleLinear<number, number, never>
  xMin: number
  xMax: number
  yMin: number
  yMax: number
  height: number
  width: number
  tooltipTitleText: string
  yToFixed: number
  xToFixed: number
}): ITooltipSettings {
  const { tooltip, tooltipTitle } = generateIsolineTooltip(el)

  const lineX = chartGroup
    .append('line')
    .style('stroke', 'var(--proximilar-font-color)')
    .style('opacity', 0)
    .attr('stroke-dasharray', '10, 5')
    .attr('stroke-width', 2)
    .attr('x1', x(xMin))
    .attr('x2', x(xMax))

  const lineY = chartGroup
    .append('line')
    .style('stroke', 'var(--proximilar-font-color)')
    .style('opacity', 0)
    .attr('stroke-dasharray', '10, 5')
    .attr('stroke-width', 2)
    .attr('y1', y(yMin))
    .attr('y2', y(yMax))

  const lineXCenter = chartGroup
    .append('line')
    .style('stroke', 'var(--proximilar-chart-info2-color)')
    .style('opacity', 0)
    .attr('stroke-width', 2)

  const lineYCenter = chartGroup
    .append('line')
    .style('stroke', 'var(--proximilar-chart-info2-color)')
    .style('opacity', 0)
    .attr('stroke-width', 2)

  const lineXValue = chartGroup.append('g').style('opacity', 0)
  lineXValue.insert('rect', 'g')
  lineXValue.insert('text', 'g').attr('fill', 'var(--proximilar-chart-tooltip-color)')

  const lineYValue = chartGroup.append('g').style('opacity', 0)
  lineYValue.insert('rect', 'g')
  lineYValue.insert('text', 'g').attr('fill', 'var(--proximilar-chart-tooltip-color)')

  const tooltipSettings: ITooltipSettings = {
    lineX,
    lineY,
    lineXCenter,
    lineYCenter,
    lineXValue,
    lineYValue,
    tooltipTitle,
    tooltip,
  }

  const rect: d3.Selection<SVGRectElement, unknown, null, undefined> = chartGroup
    .append('rect')
    .attr('height', height)
    .attr('width', width)
    .attr('opacity', 0)
    .on('mouseover', () => tooltipMouseOver(tooltipTitleText, tooltipSettings))
    .on('mousemove', d => tooltipMouseMove(d, rect.node(), x, y, yMin, xMin, yToFixed, xToFixed, tooltipSettings))
    .on('mouseout', () => tooltipMouseOut(tooltipSettings))

  return tooltipSettings
}

export function tooltipMouseMove(
  d: any,
  container: any,
  x: d3.ScaleLinear<number, number, never>,
  y: d3.ScaleLinear<number, number, never>,
  yMin: number,
  xMin: number,
  yToFixed: number,
  xToFixed: number,
  { lineX, lineY, lineXCenter, lineYCenter, lineXValue, lineYValue, tooltip }: ITooltipSettings
): void {
  const offset = 5
  const mouse = d3.pointer(d, container)
  const yValue = forecastNumber(y.invert(mouse[1]), yToFixed)
  const xValue = forecastNumber(x.invert(mouse[0]), xToFixed)

  const mouseX = mouse[0] - 5
  const mouseY = mouse[1] - 5

  lineY.attr('x1', mouseX)
  lineY.attr('x2', mouseX)
  lineX.attr('y1', mouseY)
  lineX.attr('y2', mouseY)

  lineYCenter.attr('x1', mouseX)
  lineYCenter.attr('x2', mouseX)
  lineYCenter.attr('y1', mouseY - LINE_CENTER_SIZE)
  lineYCenter.attr('y2', mouseY + LINE_CENTER_SIZE)

  lineXCenter.attr('y1', mouseY)
  lineXCenter.attr('y2', mouseY)
  lineXCenter.attr('x1', mouseX - LINE_CENTER_SIZE)
  lineXCenter.attr('x2', mouseX + LINE_CENTER_SIZE)

  const lineXValueNode = lineXValue.select('text').node()
  lineXValue.select('text').text(xValue)
  lineXValue.select('text').attr('y', y(yMin) + offset + (lineXValueNode as SVGSVGElement).getBBox().height)
  lineXValue.select('text').attr('x', mouseX - (lineXValueNode as SVGSVGElement).getBBox().width / 2)
  changeTextBgPosition(lineXValue)

  const lineYValueNode = lineYValue.select('text').node()
  lineYValue.select('text').text(yValue)
  lineYValue.select('text').attr('y', mouseY - 3 + (lineYValueNode as SVGSVGElement).getBBox().height / 2)
  lineYValue.select('text').attr('x', x(xMin) - offset - (lineYValueNode as SVGSVGElement).getBBox().width)
  changeTextBgPosition(lineYValue)

  tooltip?.style('top', `${d.layerY + offset}px`).style('left', `${d.layerX + offset}px`)
}

export function tooltipMouseOver(
  tooltipTitleText: string,
  { lineX, lineY, lineXCenter, lineYCenter, lineXValue, lineYValue, tooltip, tooltipTitle }: ITooltipSettings
): void {
  tooltipTitle?.text(tooltipTitleText)
  lineY.style('opacity', 0.5)
  lineX.style('opacity', 0.5)
  lineXCenter.style('opacity', 1)
  lineYCenter.style('opacity', 1)
  lineXValue.style('opacity', 1)
  lineYValue.style('opacity', 1)
  tooltip?.style('visibility', 'visible')
}

export function tooltipMouseOut({
  lineX,
  lineY,
  lineXCenter,
  lineYCenter,
  lineXValue,
  lineYValue,
  tooltip,
}: ITooltipSettings): void {
  lineY.style('opacity', 0)
  lineX.style('opacity', 0)
  lineXCenter.style('opacity', 0)
  lineYCenter.style('opacity', 0)
  lineXValue.style('opacity', 0)
  lineYValue.style('opacity', 0)
  tooltip?.style('visibility', 'hidden')
}

export function drawIsolineLine({
  line,
  chartGroup,
  data,
  strokeType = 'line',
  color = 'var(--proximilar-chart-line-color)',
}: {
  line: d3.Line<[number, number]>
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>
  data: { x: number; y: number }[]
  strokeType?: 'line' | 'dash'
  color?: string
}): void {
  chartGroup
    .append('path')
    .attr('d', line(data.map((o: IChartLineData) => [o.x, o.y])))
    .attr('fill', 'none')
    .attr('stroke', color)
    .attr('stroke-width', 2)
    .attr('stroke-dasharray', strokeType === 'line' ? '0, 0' : '15, 5')
}

export function drawIsolineArea({
  area,
  chartGroup,
  data,
  fill = 'var(--proximilar-chart-line-color)',
  opacity = 0.2,
  x,
  y,
  yMin,
  xMin,
  yToFixed,
  xToFixed,
  tooltipTitleText,
  tooltipSettings,
}: {
  area: d3.Line<[number, number]>
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>
  data: { x: number; y: number }[]
  fill?: string
  opacity?: number
  x: d3.ScaleLinear<number, number, never>
  y: d3.ScaleLinear<number, number, never>
  yMin: number
  xMin: number
  yToFixed: number
  xToFixed: number
  tooltipTitleText: string
  tooltipSettings: ITooltipSettings
}): void {
  const areaGroup: d3.Selection<SVGPathElement, unknown, null, undefined> = chartGroup
    .append('path')
    .attr('d', area(data.map((o: IChartLineData) => [o.x, o.y])))
    .style('fill', fill)
    .style('opacity', opacity)
    .on('mouseover', () => tooltipMouseOver(tooltipTitleText, tooltipSettings))
    .on('mousemove', d => tooltipMouseMove(d, areaGroup.node(), x, y, yMin, xMin, yToFixed, xToFixed, tooltipSettings))
    .on('mouseout', () => tooltipMouseOut(tooltipSettings))
}

export function drawIsolineLegend({
  chartGroup,
  x,
  y,
  xPos,
  yPos,
  pdfConsensus,
  pdfForecastProximilar,
  lineExpected,
}: {
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>
  x: d3.ScaleLinear<number, number, never>
  y: d3.ScaleLinear<number, number, never>
  xPos: number
  yPos: number
  pdfConsensus: string
  pdfForecastProximilar: string
  lineExpected: string
}): void {
  const padding = { x: 20, y: 10 }
  const gNode = chartGroup.append('g')

  const drawLegendInfo = (xOffset = 0, yOffset = 0): void => {
    gNode.selectAll('.legend-rect').remove()
    gNode.selectAll('text').remove()
    const xLegend = x(xPos) - xOffset
    const yLegend = y(yPos) + yOffset
    const lineYSize = 18
    const fSize = 13
    drawRectangle(gNode, xLegend, yLegend - 3 + lineYSize * 0, 5, 5, 'var(--proximilar-chart-info1-color)').attr(
      'class',
      'legend-rect'
    )
    drawRectangle(gNode, xLegend, yLegend + lineYSize * 1 - 3, 5, 5, 'var(--proximilar-chart-info2-color)').attr(
      'class',
      'legend-rect'
    )
    drawRectangle(
      gNode,
      xLegend - 4,
      yLegend + lineYSize * 2 - 3,
      6,
      4,
      'var(--proximilar-chart-line-info-color)'
    ).attr('class', 'legend-rect')
    drawRectangle(
      gNode,
      xLegend + 5,
      yLegend + lineYSize * 2 - 3,
      6,
      4,
      'var(--proximilar-chart-line-info-color)'
    ).attr('class', 'legend-rect')
    gNode
      .append('text')
      .attr('x', xLegend + 20)
      .attr('y', yLegend + lineYSize * 0)
      .text(pdfConsensus)
      .style('font-size', `${fSize}px`)
      .attr('alignment-baseline', 'middle')
      .attr('fill', 'var(--proximilar-font-color)')
    gNode
      .append('text')
      .attr('x', xLegend + 20)
      .attr('y', yLegend + lineYSize * 1)
      .text(pdfForecastProximilar)
      .style('font-size', `${fSize}px`)
      .attr('alignment-baseline', 'middle')
      .attr('fill', 'var(--proximilar-font-color)')
    gNode
      .append('text')
      .attr('x', xLegend + 20)
      .attr('y', yLegend + lineYSize * 2)
      .text(lineExpected)
      .style('font-size', `${fSize}px`)
      .attr('alignment-baseline', 'middle')
      .attr('fill', 'var(--proximilar-font-color)')
  }

  drawLegendInfo()
  const height = (gNode.node() as SVGTSpanElement).getBBox().height
  const width = (gNode.node() as SVGTSpanElement).getBBox().width + padding.x

  drawLegendInfo(width, height)
  drawTextBg(gNode, padding.x, padding.y, 1)
  drawLegendInfo(width, height)
}

export function drawXLabel(
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  width: number,
  height: number,
  title: string
): void {
  const xLabel = chartGroup.append('text')
  xLabel
    .attr('class', 'x label')
    .attr('text-anchor', 'end')
    .attr('y', height + 35)
    .attr('fill', 'var(--proximilar-light-color)')
    .text(title)
  xLabel.attr('x', width / 2 + xLabel.node()!.getBBox().width / 2)
}

export function drawYLabel(
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  width: number,
  height: number,
  title: string
): void {
  const yLabel = chartGroup.append('g')
  yLabel
    .attr('transform', `translate(${-35}, ${height / 2})`)
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('transform', 'rotate(-90)')
    .attr('fill', 'var(--proximilar-light-color)')
    .text(title)
}

export function changeTextBgPosition(
  gNode: d3.Selection<SVGGElement, unknown, null, undefined>,
  paddingX = 10,
  paddingY = 10,
  opacity = 0.9
): void {
  const textNode = gNode.select('text').node()
  gNode
    .select('rect')
    .attr('x', function () {
      return (textNode as SVGSVGElement).getBBox().x - paddingX / 2
    })
    .attr('y', function () {
      return (textNode as SVGSVGElement).getBBox().y - paddingY / 2
    })
    .attr('width', function () {
      return (textNode as SVGSVGElement).getBBox().width + paddingX
    })
    .attr('height', function () {
      return (textNode as SVGSVGElement).getBBox().height + paddingY
    })
    .attr('rx', 4)
    .style('fill', 'var(--proximilar-chart-bg-color)')
    .style('opacity', opacity)
}

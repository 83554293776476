import _ from 'lodash-es'

import { getDateISOString } from './forecast.utils'

export const getUrlParams = (data: { [key: string]: any }): string => {
  const convertValue = (v: any): any => {
    if (_.overSome(_.isNil, _.isNaN)(v)) {
      return v || '' // false, null, undefined, NaN => ''
    }

    if (Object.getPrototypeOf(v) === Date.prototype) {
      return getDateISOString(v)
    }

    if (Object.getPrototypeOf(v) === Object.prototype) {
      const obj = { ...v }
      for (const key in obj) {
        obj[key] = convertValue(obj[key])
      }
      return obj
    }
    return v
  }

  const removeEmptyValues = (data: any): any => {
    return Object.keys(data).reduce((obj: any, key) => {
      switch (Object.getPrototypeOf(data[key])) {
        case Object.prototype: {
          const v = removeEmptyValues(data[key])
          if (!_.isEmpty(v)) {
            obj[key] = v
          }
          break
        }
        default:
          if (data[key] || data[key] === 0 || data[key] === false) {
            obj[key] = data[key]
          }
          break
      }

      return obj
    }, {})
  }

  return Object.keys(data)
    .reduce((params, v) => {
      const value = convertValue(data[v])
      switch (Object.getPrototypeOf(value)) {
        case String.prototype:
        case Number.prototype:
        case Boolean.prototype:
          if (value !== '') {
            params.push(encodeURIComponent(v) + '=' + encodeURIComponent(value))
          }
          break
        default:
          const clearedValue = removeEmptyValues(value)
          params.push(encodeURIComponent(v) + '=' + encodeURIComponent(JSON.stringify(clearedValue)))
          break
      }

      return params
    }, [] as string[])
    .join('&')
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { IUserDto, IUserFolderDto } from '~/core/models'

@Injectable({
  providedIn: 'any',
})
export class UserApiService {
  constructor(private http: HttpClient) {}

  getUser(): Observable<IUserDto> {
    return this.http.get<IUserDto>('user/current/info').pipe(map(u => this.parseUser(u))) // TODO: Remove after fix
  }

  updateCurrentUser(firstName: string, lastName: string, companyName: string): Observable<IUserDto> {
    const data = { firstName, lastName, companyName }
    return this.http.patch<IUserDto>('user/current/update', data).pipe(map(u => this.parseUser(u))) // TODO: Remove after fix
  }

  getUserFolders(): Observable<IUserFolderDto[]> {
    return this.http.get<{ data: IUserFolderDto[] }>('user-folders/list').pipe(map(({ data }) => data))
  }

  toggleFavoriteForecasts(companyIds: number[]): Observable<{ companyId: number; isFavorite: boolean }[]> {
    const dataStr = companyIds.reduce((str, id) => `${str}${str ? '&' : ''}ids=${id}`, '')
    return this.http.post<{ companyId: number; isFavorite: boolean }[]>(`user-forecast/toggle-favorite/?${dataStr}`, {})
  }

  addUserFolder(folderName: string): Observable<IUserFolderDto[]> {
    return this.http.post<{ data: IUserFolderDto[] }>(`user-folders/add`, { folderName }).pipe(map(({ data }) => data))
  }

  editUserFolder(folderId: number, folderName: string): Observable<IUserFolderDto> {
    return this.http.patch<IUserFolderDto>(`user-folders/${folderId}/update`, { folderName })
  }

  deleteUserFolder(folderId: number): Observable<boolean> {
    return this.http.delete<boolean>(`user-folders/${folderId}`)
  }

  moveForecastsToUserFolder(folderId: number, companyIds: number[]): Observable<boolean> {
    return this.http.post<boolean>(`user-folders/move-forecasts`, { folderId, companyIds })
  }

  deleteForecastsFromFolder(companyIds: number[], folderId: number): Observable<number[]> {
    const dataStr = companyIds.reduce((str, id) => `${str}${str ? '&' : ''}ids=${id}`, '')
    return this.http.delete<number[]>(`user-folders/delete-forecasts/?folderId=${folderId}&${dataStr}`)
  }

  // TODO: Remove after fix
  private parseUser(user: IUserDto): IUserDto {
    return {
      ...user,
      apiKeys: user.api_keys,
    }
  }
}

import { Injectable } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'

@Injectable({
  providedIn: 'root',
})
export class MetaTagService {
  constructor(
    private metaTagService: Meta,
    private titleTagService: Title
  ) {}

  init(): void {
    this.metaTagService.addTags([
      {
        name: 'description',
        content: '',
      },
    ])

    this.setHomePageMeta()
  }

  // TODO: Add translates

  setHomePageMeta(): void {
    this.titleTagService.setTitle('Stock Market Forecasts | Earnings, Price Targets & Predictions')
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Stock Market Forecasts | Earnings, Revenues, Price Targets and Estimates',
    })
  }

  setTickerPageMeta(name: string, ticker: string): void {
    this.titleTagService.setTitle(`${name} Stock Forecasts | ${ticker} EPS & Price Predictions`)
    this.metaTagService.updateTag({
      name: 'description',
      content: `${name} Stock Forecasts | ${ticker} Earnings, Revenue and Price Estimates`,
    })
  }

  setTickerNotFoundPageMeta(ticker: string, message: string): void {
    this.titleTagService.setTitle(`Stock Forecasts | ${ticker} ${message}`)
    this.metaTagService.updateTag({
      name: 'description',
      content: `Stock Forecasts | ${ticker} Earnings, Revenue and Price Estimates`,
    })
  }

  setPerformancePageMeta(): void {
    this.titleTagService.setTitle(`Our Track Record | Earnings Forecast Performance History`)
    this.metaTagService.updateTag({
      name: 'description',
      content: `Our Track Record | Earnings Forecast Performance History`,
    })
  }

  setQuarterlyDashboardPageMeta(): void {
    this.titleTagService.setTitle(`Quarterly Dashboard Record | Earnings Forecast Quarterly History`)
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Quarterly Dashboard Record | Earnings Forecast Quarterly History',
    })
  }
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import {
  IQuarterlyDashboardMktCap,
  IQuarterlyDashboardQuarter,
  IQuarterlyDashboardSector,
  IQuarterlyDashboardSectorTop500,
} from '~/core/features'
import { IResponseListApiDto, IResponseSortListApiDto } from '~/core/models'

const QUARTERLY_DASHBOARD_PREFIX = 'quarterly-dashboard'

@Injectable({
  providedIn: 'any',
})
export class QuarterlyDashboardApiService {
  constructor(private http: HttpClient) {}

  getQuarterlyDashboardQuarterList(): Observable<IQuarterlyDashboardQuarter[]> {
    return this.http
      .get<IResponseListApiDto<IQuarterlyDashboardQuarter>>(`${QUARTERLY_DASHBOARD_PREFIX}/quarter/list`)
      .pipe(map(res => res.data))
  }

  getQuarterlyDashboardMktCap(
    year: number,
    quarter: number,
    sortBy: string,
    order: 'asc' | 'desc' | ''
  ): Observable<IResponseSortListApiDto<IQuarterlyDashboardMktCap>> {
    return this.http.get<IResponseSortListApiDto<IQuarterlyDashboardMktCap>>(
      `${QUARTERLY_DASHBOARD_PREFIX}/mkt-cap/list/${year}/${quarter}?sortBy=${sortBy}&order=${order}`
    )
  }

  getQuarterlyDashboardSector(
    year: number,
    quarter: number,
    sortBy: string,
    order: 'asc' | 'desc' | ''
  ): Observable<IResponseSortListApiDto<IQuarterlyDashboardSector>> {
    return this.http.get<IResponseSortListApiDto<IQuarterlyDashboardSector>>(
      `${QUARTERLY_DASHBOARD_PREFIX}/sector/list/${year}/${quarter}?sortBy=${sortBy}&order=${order}`
    )
  }

  getQuarterlyDashboardSectorTop500(
    year: number,
    quarter: number,
    sortBy: string,
    order: 'asc' | 'desc' | ''
  ): Observable<IResponseSortListApiDto<IQuarterlyDashboardSectorTop500>> {
    return this.http.get<IResponseSortListApiDto<IQuarterlyDashboardSectorTop500>>(
      `${QUARTERLY_DASHBOARD_PREFIX}/sector/top500/list/${year}/${quarter}?sortBy=${sortBy}&order=${order}`
    )
  }
}

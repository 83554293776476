import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'

import {
  IForecastDto,
  IForecastSearchPreviewDto,
  IResponsePaginationApiDto,
  ISearchForecastDataDto,
} from '~/core/models'
import { getUrlParams } from '~/utils/index'

const FORECAST_PREFIX = 'forecast'

@Injectable({
  providedIn: 'any',
})
export class ForecastApiService {
  constructor(private http: HttpClient) {}

  getForecastList(
    searchData: ISearchForecastDataDto,
    isFavorite = false,
    userFolderId: number | null = null
  ): Observable<IResponsePaginationApiDto<IForecastDto>> {
    const data = {
      isFavorite,
      userFolderId: userFolderId || -1,

      selectedTicker: searchData.selectedTicker,
      search: searchData.search,

      sortBy: searchData.sortBy,
      order: searchData.order,
      page: searchData.paginatorData.page,
      perPage: searchData.paginatorData.perPage,
      filters: searchData.filters,
    }
    const urlParams = getUrlParams(data)

    return this.http.get<IResponsePaginationApiDto<IForecastDto>>(`${FORECAST_PREFIX}/list?${urlParams}`)
  }

  getForecast(isCompanyPage: boolean, ticker: string): Observable<IForecastDto> {
    return this.http.get<IForecastDto>(`${FORECAST_PREFIX}/company/${ticker}?isCompanyPage=${isCompanyPage}`)
  }

  getForecastEdgar(isCompanyPage: boolean, ticker: string): Observable<any> {
    return this.http.get(`${FORECAST_PREFIX}/edgar/${ticker}?isCompanyPage=${isCompanyPage}`, {
      responseType: 'text',
    })

    return of('TMP')
    // return this.http.get<string>(`assets/document.html`, {
    //   headers: {
    //     conte,
    //   },
    // })
  }

  getSearchPreviewForecasts(
    searchText: string,
    isFavorite: boolean,
    folderId: number | null
  ): Observable<IForecastSearchPreviewDto[]> {
    const data = { searchText, isFavorite, folderId: folderId || -1 }
    const urlParams = getUrlParams(data)
    return this.http.get<IForecastSearchPreviewDto[]>(`${FORECAST_PREFIX}/search-preview/list?${urlParams}`)
  }
}

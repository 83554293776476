import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'any',
})
export class MailApiService {
  constructor(private http: HttpClient) {}

  testEmail(firstName: string, lastName: string, email: string, company: string, message: string): Observable<any> {
    const data = { firstName, lastName, email, company, message }
    return this.http.post<boolean>('test-email', data).pipe(catchError(() => [false]))
  }
}

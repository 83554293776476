import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { map, Observable } from 'rxjs'

import { IApiKeyDto, IResponseListApiDto, IResponsePaginationApiDto } from '~/core/models'

const USER_API_KEYS_PREFIX = 'user-api-keys'
const CURRENT_USER_PREFIX = 'current'

@Injectable({
  providedIn: 'any',
})
export class UserApiKeyApiService {
  constructor(private http: HttpClient) {}

  getUserKeyList(id: number): Observable<IResponsePaginationApiDto<IApiKeyDto>> {
    return this.http.get<IResponsePaginationApiDto<IApiKeyDto>>(`${USER_API_KEYS_PREFIX}/${id}`)
  }

  addUserApiKey(id: number): Observable<IApiKeyDto[]> {
    return this.http
      .post<IResponseListApiDto<IApiKeyDto>>(`${USER_API_KEYS_PREFIX}/${id}`, null)
      .pipe(map(({ data }) => data))
  }

  deleteUserApiKeys(id: number, ids: number[]): Observable<IApiKeyDto[]> {
    const dataStr = ids.reduce((str, id) => `${str}${str ? '&' : ''}ids=${id}`, '')
    return this.http
      .delete<IResponseListApiDto<IApiKeyDto>>(`${USER_API_KEYS_PREFIX}/${id}?${dataStr}`)
      .pipe(map(({ data }) => data))
  }

  getCurrentUserKeyList(): Observable<IApiKeyDto[]> {
    return this.http
      .get<IResponseListApiDto<IApiKeyDto>>(`${USER_API_KEYS_PREFIX}/${CURRENT_USER_PREFIX}`)
      .pipe(map(({ data }) => data))
  }

  createCurrentUserApiKey(): Observable<IApiKeyDto[]> {
    return this.http
      .post<IResponseListApiDto<IApiKeyDto>>(`${USER_API_KEYS_PREFIX}/${CURRENT_USER_PREFIX}`, null)
      .pipe(map(({ data }) => data))
  }

  deleteCurrentUserApiKeys(ids: number[]): Observable<IApiKeyDto[]> {
    const dataStr = ids.reduce((str, id) => `${str}${str ? '&' : ''}ids=${id}`, '')
    return this.http
      .delete<IResponseListApiDto<IApiKeyDto>>(`${USER_API_KEYS_PREFIX}/${CURRENT_USER_PREFIX}?${dataStr}`)
      .pipe(map(({ data }) => data))
  }
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import {
  ICompanyDto,
  ICompanyPriceDto,
  IContourEpsDto,
  IEpsCdfDto,
  IEpsPdfDto,
  IIsolineEpsDto,
  IResponseListApiDto,
  IRevPdfDto,
} from '~/core/models'
import { EChartPeriod } from '~/core/models'

const COMPANY_PREFIX = 'company'

@Injectable({
  providedIn: 'any',
})
export class CompanyApiService {
  constructor(private http: HttpClient) {}

  getPrices(companyId: number, chartPeriod: EChartPeriod, isCompanyPage: boolean): Observable<ICompanyPriceDto[]> {
    return this.http
      .get<
        IResponseListApiDto<ICompanyPriceDto>
      >(`${COMPANY_PREFIX}/${companyId}/prices/list/?chartPeriod=${chartPeriod}&isCompanyPage=${isCompanyPage}`)
      .pipe(map(res => res.data))
  }

  getPeers(companyId: number, isCompanyPage: boolean): Observable<ICompanyDto[]> {
    return this.http
      .get<
        IResponseListApiDto<ICompanyDto>
      >(`${COMPANY_PREFIX}/${companyId}/peers/list/?isCompanyPage=${isCompanyPage}`)
      .pipe(map(res => res.data))
  }

  getEpsPdf(companyId: number, isCompanyPage: boolean): Observable<IEpsPdfDto[]> {
    return this.http
      .get<
        IResponseListApiDto<IEpsPdfDto>
      >(`${COMPANY_PREFIX}/${companyId}/eps-pdf/list/?isCompanyPage=${isCompanyPage}`)
      .pipe(map(res => res.data))
  }

  getRevPdf(companyId: number, isCompanyPage: boolean): Observable<IRevPdfDto[]> {
    return this.http
      .get<
        IResponseListApiDto<IRevPdfDto>
      >(`${COMPANY_PREFIX}/${companyId}/rev-pdf/list/?isCompanyPage=${isCompanyPage}`)
      .pipe(map(res => res.data))
  }

  getEpsCdf(companyId: number, isCompanyPage: boolean): Observable<IEpsCdfDto[]> {
    return this.http
      .get<
        IResponseListApiDto<IEpsCdfDto>
      >(`${COMPANY_PREFIX}/${companyId}/eps-cdf/list/?isCompanyPage=${isCompanyPage}`)
      .pipe(map(res => res.data))
  }

  getContourEps(companyId: number, isCompanyPage: boolean): Observable<IContourEpsDto[]> {
    return this.http
      .get<
        IResponseListApiDto<IContourEpsDto>
      >(`${COMPANY_PREFIX}/${companyId}/contour-eps/list/?isCompanyPage=${isCompanyPage}`)
      .pipe(map(res => res.data))
  }

  getImpactIsolines(companyId: number, isCompanyPage: boolean): Observable<IIsolineEpsDto[]> {
    return this.http
      .get<
        IResponseListApiDto<IIsolineEpsDto>
      >(`${COMPANY_PREFIX}/${companyId}/impact-isolines/list/?isCompanyPage=${isCompanyPage}`)
      .pipe(map(res => res.data))
  }
}

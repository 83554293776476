import { forecastNumber } from './forecast.utils'

export function drawInfoLine(
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  lineSettings: string | null
): void {
  drawLine(chartGroup, lineSettings, 'var(--proximilar-chart-bg-group1-color)', 1, 0.6)
}

export function drawLine(
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  lineSettings: string | null,
  color: string,
  strokeWidth = 2,
  opacity = 1
): void {
  chartGroup
    .append('path')
    .attr('d', lineSettings)
    .attr('fill', 'none')
    .attr('stroke', color)
    .style('opacity', opacity)
    .attr('stroke-width', strokeWidth)
}

export function drawDot(
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  xPos: number,
  yPos: number,
  color = 'var(--proximilar-accent-color)',
  radius = 5
): void {
  chartGroup.append('circle').attr('cx', xPos).attr('cy', yPos).attr('r', radius).style('fill', color)
}

export function drawRectangle(
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  xPos: number,
  yPos: number,
  width: number,
  height: number,
  color: string,
  opacity = 1
): d3.Selection<SVGRectElement, unknown, null, undefined> {
  return chartGroup
    .append('rect')
    .attr('x', xPos)
    .attr('y', yPos)
    .attr('width', width)
    .attr('height', height)
    .attr('fill', color)
    .style('opacity', opacity)
}

export function addInfoValue(
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  title: string | number,
  x: d3.ScaleLinear<number, number, never>,
  y: d3.ScaleLinear<number, number, never>,
  xVal: number,
  yVal: number,
  numberSettings: { toFixed: number },
  positionX: 'left' | 'right' = 'left',
  color = 'var(--proximilar-font-color)'
): d3.Selection<SVGGElement, unknown, null, undefined> {
  const marginY = 20
  const gNode = chartGroup.append('g')
  const padding = { x: 20, y: 10 }

  const drawText = (): void => {
    gNode.select('text').remove()
    const textNode = gNode
      .insert('text', 'g')
      .attr('y', y(yVal) - marginY)
      .attr('x', x(xVal))
      .attr('text-anchor', positionX === 'left' ? 'end' : 'start')
      .attr('fill', color)

    if (title) {
      textNode.insert('tspan', 'text').attr('x', x(xVal)).attr('dy', 0).text(title)
    }

    textNode
      .insert('tspan', 'text')
      .attr('x', x(xVal))
      .attr('dy', '1.2em')
      .text(forecastNumber(xVal, numberSettings.toFixed))
  }

  drawText()
  drawTextBg(gNode, padding.x, padding.y)
  drawText()
  return gNode
}

export function addInfo({
  chartGroup,
  title,
  xVal,
  yVal,
  positionX = 'left',
  color = 'var(--proximilar-font-color)',
  opacity = 0.8,
  fontSize = '0.8rem',
  transform = '',
}: {
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>
  title: string | number
  xVal: number
  yVal: number
  positionX?: 'left' | 'right'
  color?: string
  opacity?: number
  fontSize?: string
  transform?: string
}): void {
  const marginY = 0
  const gNode = chartGroup.append('g')
  const padding = { x: 20, y: 10 }

  const drawText = (): void => {
    gNode.select('text').remove()
    const textNode = gNode
      .insert('text', 'g')
      .attr('y', yVal - marginY)
      .attr('x', xVal)
      .attr('text-anchor', positionX === 'left' ? 'end' : 'start')
      .attr('fill', color)
      .attr('opacity', opacity)

    if (title) {
      textNode.insert('tspan', 'text').attr('x', xVal).attr('dy', 0).text(title).style('font-size', fontSize)
    }
  }

  drawText()
  drawTextBg(gNode, padding.x, padding.y)
  drawText

  gNode.style('transform', transform)
}

export function addInfoTextBlock(
  chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  x: d3.ScaleLinear<number, number, never>,
  y: d3.ScaleLinear<number, number, never>,
  xPos: number,
  yPos: number,
  maxWidth: number,
  text: string
): void {
  const gNode = chartGroup.append('g')
  const padding = { x: 20, y: 10 }

  let textNode = gNode
    .insert('text', 'g')
    .attr('y', yPos)
    .attr('x', function () {
      return x(xPos)
    })
    .attr('text-anchor', 'middle')
    .text(text)

  const width = (gNode.select('text').node() as SVGTSpanElement).getComputedTextLength()
  const countLines = Math.ceil(width / maxWidth)
  const minSymbols = Math.ceil(text.length / countLines)

  const arr = []

  // TODO: Refactoring
  for (let i = 1, lastIndex = 0; i < text.length; i++) {
    if (i % minSymbols === 0 || i === text.length - 1) {
      for (let j = i; j < text.length; i++, j++) {
        if (text[i] === ' ' || j === text.length - 1) {
          arr.push(text.slice(lastIndex, i + 1).trim())
          lastIndex = i
          break
        }
      }
    }
  }

  // const height = (gNode.node() as SVGTSpanElement).getBBox().height
  const yTextPos = yPos - padding.y // - ((arr.length + 1) * height) / 2

  gNode.select('text').remove()
  textNode = gNode
    .insert('text', 'g')
    .attr('y', yTextPos)
    .attr('x', function () {
      return x(xPos)
    })
    .attr('text-anchor', 'middle')

  for (let i = 0; i < arr.length; i++) {
    const dy = i ? '1.2em' : '0'
    textNode
      .insert('tspan', 'text')
      .attr('x', function () {
        return x(xPos)
      })
      .attr('dy', dy)
      .text(arr[i])
  }

  drawTextBg(gNode, padding.x, padding.y)

  gNode.select('text').remove()
  textNode = gNode
    .insert('text', 'g')
    .attr('y', yTextPos)
    .attr('x', function () {
      return x(xPos)
    })
    .attr('text-anchor', 'middle')
    .attr('fill', 'var(--proximilar-font-color)')

  for (let i = 0; i < arr.length; i++) {
    const dy = i ? '1.2em' : '0'
    textNode
      .insert('tspan', 'text')
      .attr('x', function () {
        return x(xPos)
      })
      .attr('dy', dy)
      .text(arr[i])
  }
}

export function drawTextBg(
  gNode: d3.Selection<SVGGElement, unknown, null, undefined>,
  paddingX: number,
  paddingY: number,
  opacity = 0.1
): void {
  gNode
    .insert('rect', 'g')
    .attr('x', function () {
      return (this.parentNode as SVGSVGElement).getBBox().x - paddingX / 2
    })
    .attr('y', function () {
      return (this.parentNode as SVGSVGElement).getBBox().y - paddingY / 2
    })
    .attr('width', function () {
      return (this.parentNode as SVGSVGElement).getBBox().width + paddingX
    })
    .attr('height', function () {
      return (this.parentNode as SVGSVGElement).getBBox().height + paddingY
    })
    .attr('rx', 4)
    .style('fill', 'var(--proximilar-chart-bg-color)')
    .style('opacity', opacity)
}

export const getDataClosedValue = (data: number[], value: number): number => {
  let lowDiff = 1e99
  let xI = 0

  for (let i = 0; i < data.length; i++) {
    let diff = Math.abs(value - data[i])
    if (diff < lowDiff) {
      lowDiff = diff
      xI = i
    }
  }

  return data[xI]
}

export const getInvertIndexBandScales = (pos: number, scale: d3.ScaleBand<any>): number => {
  const eachBand = scale.step()
  const index = Math.round(pos / eachBand)
  return index
}
